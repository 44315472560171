import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import styles from "./Cards.module.css"
import { NavLink, Redirect, Route, Switch, useHistory, useParams, useRouteMatch } from "react-router-dom";
import DataContext from "../../data/DataContext";
import SText from "../../components/SText";
import cn from "classnames";
import Header from "../../components/Header";
import MobileExtraInfo from "./components/MobileExtraInfo";
import Banner from "../../components/Banner";
import NotFound from "../../components/NotFound";
import BreadCrumbs from "../../components/BreadCrumbs";
import { Helmet } from "react-helmet";
import { ProductCard } from "../../components/ProductCard";

const Catalogue = () => {
    const { catalogue } = useContext(DataContext)

    if (!catalogue.data?.categories) return null

    return <div>
        <Header />
        <MobileExtraInfo />
        <Switch>
            <Route exact path={ '/catalog' }>
                <Redirect to={ '/catalog/' + catalogue.data?.categories[0].url } />
            </Route>
            { catalogue.data.categories.map(category => (
                <Route path={ '/catalog/' + category.url }>
                    <Helmet>
                        <title>{ category.name === 'Популярные продукты' ? 'HalalFoods - фермерские продукты с доставкой на дом' : category.title_browser }</title>
                        <meta name="description" content={ category.desc } />
                        <meta name="keywords" content={ category.meta } />
                    </Helmet>
                    <CategoryEmbedRouter categoryUrl={ category.url } />
                </Route>
            )) }
        </Switch>
    </div>
}

const CategoryEmbedRouter = ({ categoryUrl }) => {
    const { catalogue } = useContext(DataContext)

    const category = catalogue.data.categories.find(category => category.url === categoryUrl)

    return <Switch>
        { category.subCategories.map((subCategory) => (
            <Route path={ '/catalog/' + categoryUrl + '/' + subCategory.url }>
                <Helmet>
                    <title>{ category.name === 'Скидки и акции' ? 'Season Market' : subCategory.title_browser }</title>
                    <meta name="description" content={ category.desc } />
                    <meta name="keywords" content={ category.meta } />
                </Helmet>
                <CategoryGrid categoryName={ category.name } subCategoryName={ subCategory.name } categoryUrl={ categoryUrl }
                    subCategoryUrl={ subCategory.url } />
            </Route>
        )) }
        <Route path={ '/catalog/' + categoryUrl }>
            <CategoryGrid categoryUrl={ categoryUrl } categoryName={ category.name } />
        </Route>
        <Route path={ "*" }>
            <NotFound />
        </Route>
    </Switch>
}

const CategoryGrid = ({ categoryUrl = null, subCategoryUrl = null, categoryName = '', subCategoryName = '' }) => {
    const { catalogue } = useContext(DataContext)
    const { push } = useHistory()

    const isFirstCat = useMemo(() => {
        return catalogue.data.categories.findIndex(category => categoryUrl === category.url) === 0
    }, [categoryUrl, subCategoryUrl])


    const category = useMemo(() => {
        return catalogue.data.categories.find(category => categoryUrl === category.url)
    }, [categoryUrl, catalogue.data])

    const toNextCat = useCallback(() => {
        window.scrollTo({ top: 120, behavior: 'smooth' })

        const isLastInCat = category?.subCategories?.length ? subCategoryUrl === [...category.subCategories].pop().url : true

        if (isLastInCat) {
            const nextCatUrl = catalogue.data?.categories[(catalogue.data?.categories?.findIndex(cat => cat.url === categoryUrl)) + 1]?.url || catalogue.data?.categories[0]?.url || ''
            const nextCat = catalogue.data?.categories[(catalogue.data?.categories?.findIndex(cat => cat.url === categoryUrl)) + 1]
            if (nextCat && nextCat.subCategories.length) {
                const nextSubCatUrl = nextCat?.subCategories[0].url || ''
                push('/catalog/' + nextCatUrl + '/' + nextSubCatUrl)
            } else {
                push('/catalog/' + nextCatUrl)
            }
        } else {
            const nextSubCatUrl = category?.subCategories[category.subCategories?.findIndex(subcat => subcat.url === subCategoryUrl) + 1]?.url || ''
            push('/catalog/' + categoryUrl + '/' + nextSubCatUrl)
        }

    }, [categoryUrl, subCategoryUrl, catalogue.data])

    const selectorOptions = [/*{
        title: 'Все',
        to: '/catalog/' + categoryUrl,
        isActive: subCategoryUrl === null,
    },*/ ...category.subCategories.map((subCategory, index) => ({
        title: subCategory.name,
        to: '/catalog/' + categoryUrl + '/' + subCategory.url,
        isActive: subCategoryUrl === subCategory.url
    }))]


    const { products, outOfStockProducts } = useMemo(() => {
        let outOfStockProducts = []
        let inStockProducts = []
        if (subCategoryUrl) {
            const subCategoryId = category.subCategories?.find(subCategory => subCategory.url === subCategoryUrl)?.id
            inStockProducts = catalogue.data.products.filter(product => {
                const inThisCategory = product?.slave_category?.includes(Number(subCategoryId))
                if (inThisCategory && product.availableones == 0)
                    outOfStockProducts.push(product)
                return (inThisCategory && product.availableones != 0)
            })
        } else {
            inStockProducts = catalogue.data.products.filter(product => {
                const inThisCategory = product?.master_category?.includes(Number(category.id))
                if (inThisCategory && product.availableones == 0)
                    outOfStockProducts.push(product)
                return (inThisCategory && product.availableones != 0)
            })
        }

        return {
            products: inStockProducts,
            outOfStockProducts
        }
    }, [categoryUrl, subCategoryUrl, catalogue.data])

    const breadCrumbs = useMemo(() => {
        return [
            {
                name: categoryName,
                to: '/catalog/' + categoryUrl
            },
            subCategoryUrl ? {
                name: subCategoryName,
                to: '/catalog/' + categoryUrl + '/' + subCategoryUrl
            } : null
        ].filter(v => !!v)
    }, [categoryUrl, subCategoryUrl])

    return <div>
        { isFirstCat && <Banner /> }
        <div className={ styles.container }>
            <BreadCrumbs _items={ breadCrumbs } />
            <SText fontWeight={ 900 } fontSize={ 24 } style={ { marginLeft: 0 } }>{ category.name }</SText>
            <div className={ styles.subcatSelector }>
                { selectorOptions.map(option => (
                    <NavLink to={ option.to } className={ cn(styles.subCatItem, option.isActive && styles.subCatActive) }>
                        <SText fontSize={ 14 } className={ styles.subCatText }
                            fontWeight={ 400 } color={ option.isActive ? '#fff' : '#213140' }>
                            { option.title }
                        </SText>
                    </NavLink>
                )) }
            </div>
            <div className={ styles.wrapper }>
                { products?.map(product => <ProductCard name={ product.title }
                    image={ product.medium_image }
                    id={ product.id }
                    key={ product.id }
                    weight={ product.weight }
                    measure={ 'кг' }
                    price={ product.price }
                    { ...product }
                />) }
            </div>
            <div className={ styles.nextSectionContainer }>
                <button onClick={ toNextCat } className={ styles.nextSectionButton }>
                    <SText fontSize={ 16 } fontWeight={ 900 }>{ 'В следующий раздел >>' }</SText>
                </button>
            </div>
            { outOfStockProducts.length > 0 && categoryName !== 'Скидки и акции' && <div>
                <div style={ { marginBottom: 24, marginTop: 50 } }>
                    <div style={ { marginBottom: 20 } }><SText fontSize={ 24 }
                        fontWeight={ 900 }>{ 'Эти товары закончились' }</SText>
                    </div>
                    <SText fontSize={ 18 }
                        fontWeight={ 700 }>{ 'Но вы можете настроить уведомление о поступлении товара' }</SText>
                </div>
                <div className={ styles.wrapper }>
                    { outOfStockProducts?.map(product => <ProductCard name={ product.title }
                        image={ product.medium_image }
                        id={ product.id }
                        key={ product.id }
                        weight={ product.weight }
                        measure={ 'кг' }
                        price={ product.price }
                        out
                        { ...product }
                    />) }
                </div>
                <div className={ styles.nextSectionContainer }>
                    <button onClick={ toNextCat } className={ styles.nextSectionButton }>
                        <SText fontSize={ 16 } fontWeight={ 900 }>{ 'В следующий раздел >>' }</SText>
                    </button>
                </div>
            </div> }
            <div></div>
        </div>
    </div>
}

export default Catalogue
