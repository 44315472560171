import React, { useContext } from "react";
import TouchableOpacity from "./TouchableOpacity";
import styles from "./Region.module.css";
import closeIcon from "../assets/images/closeButton.svg";
import SText from "./SText";
import RoundedButton from "./RoundedButton";
import Colors from "../utils/Colors";
import { useWindowDimensions } from "../utils/hooks";
import DataContext from "../data/DataContext";
import { RegionContext } from "../App";

export default ({ onClose }) => {

    const { isMobile, windowDimensions: { width } } = useWindowDimensions()


    const { region, setRegion: set } = useContext(RegionContext)

    function setRegion(region) {
        set(region)
    }


    if (isMobile) {
        return <div className={ styles.wrapper }>
            <TouchableOpacity onClick={ onClose }>
                <div className={ styles.circle }>
                    <img src={ closeIcon } />
                </div>
            </TouchableOpacity>
            <div className={ styles.header }>
                <SText fontSize={ 16 } fontWeight={ 900 }>ВЫБЕРИТЕ СВОЙ РЕГИОН</SText>
            </div>
            <RoundedButton containerStyle={ { marginBottom: '33px', width: '223px', height: '50px' } }
                onPress={ () => {
                    setRegion(77);
                    onClose();
                } }
                label={ 'Москва' }
                activeColor={ region == 77 ? Colors.orange : Colors.darkBlue } />
            {/*<RoundedButton containerStyle={{width:'223px', height:'50px'}}
                           label={'Санкт-Петербург'}
                           onPress={()=>{setRegion(78);onClose();}}
                           activeColor={region == 78 ? Colors.orange : Colors.darkBlue}/>*/}
        </div>
    } else return <div className={ styles.wrapper }>
        <div className={ styles.header }>
            <SText style={ { marginRight: '30px' } } fontSize={ 18 } fontWeight={ 900 }>ВЫБЕРИТЕ СВОЙ РЕГИОН</SText>
            <TouchableOpacity onClick={ onClose }>
                <div className={ styles.circle }>
                    <img src={ closeIcon } />
                </div>
            </TouchableOpacity>
        </div>
        <RoundedButton containerStyle={ { marginBottom: '33px', width: '259px', height: '50px' } }
            label={ 'Москва' }
            onPress={ () => {
                setRegion(77);
                onClose();
            } }
            activeColor={ region == 77 ? Colors.orange : Colors.darkBlue } />
        <RoundedButton containerStyle={ { width: '259px', height: '50px' } }
            label={ 'Санкт-Петербург' }
            onPress={ () => { setRegion(78); onClose(); } }
            activeColor={ region == 78 ? Colors.orange : Colors.darkBlue } />
    </div>
}
