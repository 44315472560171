import {Redirect, useHistory, useParams} from "react-router-dom";
import DataContext from "../../data/DataContext";
import {useCoolDown, useRestrictions, useWindowDimensions} from "../../utils/hooks";
import {between, round} from "../../utils/other";
import styles from "./ProductInfo.module.css";
import SText from "../../components/SText";
import TouchableOpacity from "../../components/TouchableOpacity";
import minus from "../../assets/images/minusWhite.svg";
import garbage from "../../assets/images/garbageWhite.svg";
import plus from "../../assets/images/plusWhite.svg";
import whiteCart from "../../assets/images/whiteCart.svg"
import RoundedButton from "../../components/RoundedButton";
import Colors from "../../utils/Colors";
import React, {useContext, useEffect, useMemo, useState} from "react";
import Header from "../../components/Header";
import MobileExtraInfo from "../Catalogue/components/MobileExtraInfo";
import BreadCrumbs from "../../components/BreadCrumbs";
import {Helmet} from "react-helmet";
import InformAboutAdmission from "../../components/InformAboutAdmission";
import NewModal from "../../components/NewModal";
import placeholder from "../../assets/images/productImagePlaceholder.png"
import cn from "classnames";
import SimilarProductsCarousel from "../../components/SimilarProductsCarousel";
import {getWeight, ProductCard} from "../../components/ProductCard";
import freeze from "../../assets/images/badges/freeze.svg"
import weekly from "../../assets/images/badges/weekly.svg";
import newProduct from "../../assets/images/badges/new.svg";
import preparation from "../../assets/images/badges/preparation.svg";
import grain from "../../assets/images/badges/grain.svg";
import halal from '../../assets/images/halal.svg'
import back from '../../assets/images/back.svg'

const Badge = ({img}) => {
    return <div className={styles.badge}><img src={img}/></div>
}

const ProductInfo = () => {

    const {url} = useParams()
    const {getProduct, cart, catalogue} = useContext(DataContext)

    const [product, setProduct] = useState(null)
    const [informModalVisible, setInformModalVisible] = useState(false)
    const history = useHistory()

    const {push} = useHistory()

    async function fetchProduct() {
        const productId = catalogue.data?.products?.find(prod => prod.url == url)?.id
        if (!productId) return push('/')

        const response = await getProduct(productId)

        if (productId && response?.id)
            setProduct(response)
        else push('/')
    }

    const getBadges = () => {
        let badges = []
        if (product.week == 1) badges.push(weekly)
        if (product.freeze == 1) badges.push(freeze)
        if (product.new == 1) badges.push(newProduct)
        if (product.preparation == 1) badges.push(preparation)
        if (product.grain == 1) badges.push(grain)
        if (product.halal == 1) badges.push(halal)
        return badges.map(badge => <Badge img={badge}/>)
    }

    const breadCrumbs = useMemo(() => {
        const category = catalogue.data?.categories?.find(category => {
            if (product) {
                let cat = JSON.parse(product?.master_category)
                return cat?.includes(Number(category?.id))
            }
            return product?.master_category?.includes(Number(category?.id))
        })
        return [
            {
                name: category?.name,
                to: '/catalog/' + category?.url
            },
            {
                name: product?.title,
                to: '/product/' + product?.url,
            }
        ]
    }, [product])

    useEffect(() => {
        fetchProduct()
        window.scrollTo({top: 0, behavior: 'smooth'})

    }, [url])

    const {isMobile, windowDimensions: {width}} = useWindowDimensions(600)

    const [count, setCount] = useState(0)

    const recordValue = useCoolDown(cart.update, 600)

    function _handleCountChange(delta) {
        try {
            let _tmr = window._tmr || (window._tmr = []);
            _tmr.push({"type": "reachGoal", "id": 3220587, "goal": "addtocart"});

            window.fbq('track', 'AddToCart');
        } catch {
        }

        if (product.availableones < 1)
            return setInformModalVisible(true)
        let newValue = between(0, count + delta, product.availableones || 0)
        setCount(newValue)
        recordValue(product.product_id, newValue)
    }

    // const handleCountChange = useRestrictions(_handleCountChange)
    const handleCountChange = _handleCountChange

    useEffect(() => {
        const amount = cart.data?.productList?.find(item => item.product_id == product?.product_id)?.quantity || 0
        setCount(amount)
    }, [product?.product_id, cart.data?.productList])

    console.log(product)

    if (!product) return null

    const oldSum = product.old_price && round(Math.max(1, count) * product.old_price)
    const newSum = round(Math.max(1, count) * product.price)
    const sale = Math.round(100 - newSum / oldSum * 100)
    console.log(product.big_image)

    function render() {
        if (isMobile) {
            return <>
                <NewModal isVisible={informModalVisible} onClose={() => setInformModalVisible(false)} fullScreen={false}
                          minWidth={width > 790 ? 781 : 303}>
                    <InformAboutAdmission onClose={() => setInformModalVisible(false)} productId={product.product_id}/>
                </NewModal>
                <div className={styles.background}>
                    <MobileExtraInfo/>
                    <div className={styles.wrapper}>
                        <div style={{padding: '0 20px'}}><BreadCrumbs _items={breadCrumbs}/></div>

                        <div style={{marginBottom:9}}><img onClick={()=>history.goBack()} src={back}/></div>

                        <div className={styles.product}>
                            <div className={styles.productImg}>
                                <div className={styles.badgesWrapper}>
                                    {getBadges()}
                                </div>
                                <img src={product?.big_image || product?.medium_image} onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = placeholder
                                }}/>
                            </div>
                            <div className={styles.mainInfo}>
                                <div className={styles.productName}><SText fontSize={26}
                                                                           fontWeight={900}>{product.title}</SText>
                                </div>
                                <div className={styles.productWeight}><SText fontSize={14}
                                                                             fontWeight={700}>{getWeight(product.weight, product.measure_unit)}</SText>
                                </div>
                                <div className={styles.priceBlock}>
                                    <div className={styles.price}>
                                        {oldSum !== 0 && <div className={styles.saleWrapper}>
                                            <div className={styles.oldPrice}>
                                                <div style={{display: 'flex', alignItems: 'flex-end'}}><SText
                                                    fontSize={16} fontWeight={550} lineHeight={12}
                                                    color={'#BDBDBD'}>{Math.ceil(oldSum)}</SText>
                                                    <SText fontSize={12} fontWeight={400} color={'#BDBDBD'}
                                                           style={{fontWeight: 900}}>{'\u00A0₽'}</SText>

                                                </div>
                                            </div>
                                            <div className={styles.sale}><SText fontSize={16} fontWeight={700}
                                                                                color={'#FFF'}>{`-${sale}%`}</SText>
                                            </div>
                                        </div>}
                                        <div className={styles.priceWrapper}>
                                            <div className={styles.newPrice}>

                                                <div style={{display: 'flex', alignItems: 'flex-end'}}><SText
                                                    fontSize={23}
                                                    fontWeight={900} lineHeight={20}>{Math.ceil(newSum)}</SText>
                                                    <SText fontSize={14} fontWeight={700}
                                                           style={{fontWeight: 900}}>{'\u00A0₽'}</SText>

                                                </div>
                                            </div>
                                            <div className={styles.weight}>
                                                <SText fontSize={13}
                                                       fontWeight={500} lineHeight={8}>
                                                    {`за ` + getWeight(product.weight, product.measure_unit)}
                                                </SText>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        {count > 0
                                            ? <div style={{position: 'relative'}} className={styles.counter}>
                                                {product.availableones == count
                                                    && <div
                                                        className={styles.quantityIsLimited}>{width > 768 ? 'Количество данного товара ограничено' : 'Количество данного товара\u00A0 ограничено'}</div>}
                                                {count >= 2 ? <TouchableOpacity onClick={() => handleCountChange(-1)}>
                                                        <div className={styles.minus}><img src={minus}/></div>
                                                    </TouchableOpacity>
                                                    : <TouchableOpacity onClick={() => handleCountChange(-1)}>
                                                        <div className={styles.garbage}><img src={garbage}/></div>
                                                    </TouchableOpacity>}
                                                <div><SText fontSize={26} fontWeight={550}
                                                            color={'#213140'}>{count}</SText>
                                                </div>
                                                {product.availableones == count
                                                    ? <div style={{opacity: '50%'}}
                                                           className={cn(styles.plus)}>
                                                        <img src={plus}/></div>
                                                    : <TouchableOpacity onClick={() => handleCountChange(1)}>
                                                        <div className={styles.plus}>
                                                            <img src={plus}/>
                                                        </div>
                                                    </TouchableOpacity>}
                                            </div>
                                            : product.availableones > 0
                                                ? <RoundedButton onPress={() => handleCountChange(1)}
                                                                 containerStyle={{width: '170px', height: '50px'}}
                                                                 activeColor={Colors.orange}>
                                                    <img src={whiteCart}/>
                                                </RoundedButton>
                                                : <RoundedButton onPress={() => handleCountChange(1)}
                                                                 containerStyle={{width: '170px', height: '50px'}}
                                                                 label={'Сообщить о\u00A0поступлении'}
                                                                 activeColor={Colors.darkBlue}/>
                                        }
                                    </div>
                                </div>
                                <div className={styles.description}
                                     dangerouslySetInnerHTML={{__html: product.description}}>

                                </div>

                                {!!product.sostav ? <div className={styles.composition}>
                                    <SText fontSize={14} fontWeight={400}>
                                        {`Состав: ${product.sostav}`}
                                    </SText>
                                </div> : null}
                                {(!!product.bel_amount && !!product.fats_amount && !!product.ugl_amount) ?
                                    <div className={styles.proteins}>
                                        <SText fontSize={14} fontWeight={400}>
                                            {`Пищевая ценность: белки - ${product.bel_amount}, жиры - ${product.fats_amount}, углеводы - ${product.ugl_amount} г (в 100 г продукта).`}
                                        </SText>
                                    </div> : null}
                                {!!product.calories_amount ? <div className={styles.energy}>
                                    <SText fontSize={14} fontWeight={400}>
                                        {`Энергетическая ценность: ${product.calories_amount} ккал (в 100 г продукта).`}
                                    </SText>
                                </div> : null}

                            </div>
                        </div>

                        <div style={{marginBottom: 30}}>
                            <SText fontSize={16} fontWeight={700} lineHeight={16}>С этим товаром также покупают:</SText>
                        </div>

                        <div className={styles.recommended}>
                            {
                                product.recommended.map(prod => <ProductCard id={prod.product_id} key={prod.id}/>)
                            }
                        </div>
                    </div>
                </div>
            </>
        } else return <div className={styles.background}>
            <NewModal isVisible={informModalVisible} onClose={() => setInformModalVisible(false)} fullScreen={false}
                      minWidth={width > 790 ? 781 : 303}>
                <InformAboutAdmission onClose={() => setInformModalVisible(false)} productId={product.product_id}/>
            </NewModal>
            {width < 992 && <MobileExtraInfo/>}
            <div className={styles.wrapper}>
                <BreadCrumbs _items={breadCrumbs}/>
                <div className={styles.product}>
                    <div className={styles.productImg}>
                        <div className={styles.badgesWrapper}>
                            {getBadges()}
                        </div>
                        <img src={product?.big_image || product?.medium_image} onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = placeholder
                        }}/>
                    </div>
                    <div className={styles.mainInfo}>
                        <div className={styles.productName}><SText className={styles.productName} fontSize={28}
                                                                   fontWeight={900}>{product.title}</SText>
                        </div>
                        <div className={styles.productWeight}><SText fontSize={20}
                                                                     fontWeight={700}>{getWeight(product.weight, product.measure_unit)}</SText>
                        </div>
                        <div className={styles.priceBlock}>
                            <div className={styles.price}>
                                {oldSum !== 0 && <div className={styles.saleWrapper}>
                                    <div className={styles.oldPrice}>

                                        <div style={{display: 'flex', alignItems: 'flex-end'}}><SText fontSize={16}
                                                                                                      fontWeight={700}
                                                                                                      lineHeight={14}
                                                                                                      color={'#BDBDBD'}>{Math.ceil(oldSum)}</SText>
                                            <SText fontSize={12} fontWeight={700} color={'#BDBDBD'}
                                                   style={{fontWeight: 900}}>{'\u00A0₽'}</SText>

                                        </div>
                                    </div>
                                    <div className={styles.sale}><SText fontSize={16} fontWeight={700}
                                                                        color={'#FFF'}>{`-${sale}%`}</SText>
                                    </div>
                                </div>}
                                <div className={styles.priceWrapper}>
                                    <div className={styles.newPrice}>

                                        <div style={{display: 'flex', alignItems: 'flex-end'}}><SText fontSize={28}
                                                                                                      lineHeight={20}
                                                                                                      fontWeight={700}>{Math.ceil(newSum)}</SText>
                                            <SText fontSize={16} fontWeight={700}
                                                   style={{fontWeight: 900}}>{'\u00A0₽'}</SText>

                                        </div>
                                    </div>
                                    <div className={styles.weight}><SText fontSize={14}
                                                                          fontWeight={700}>{`за ` + getWeight(product.weight, product.measure_unit)}</SText>
                                    </div>
                                </div>
                            </div>
                            <div>
                                {count > 0
                                    ? <div style={{position: 'relative'}} className={styles.counter}>
                                        {product.availableones == count
                                            && <div
                                                className={styles.quantityIsLimited}>{width > 768 ? 'Количество данного товара ограничено' : 'Количество данного товара\u00A0 ограничено'}</div>}
                                        {count >= 2 ? <TouchableOpacity onClick={() => handleCountChange(-1)}>
                                                <div className={styles.minus}><img src={minus}/></div>
                                            </TouchableOpacity>
                                            : <TouchableOpacity onClick={() => handleCountChange(-1)}>
                                                <div className={styles.garbage}><img src={garbage}/></div>
                                            </TouchableOpacity>}
                                        <div><SText fontSize={26} fontWeight={550} color={'#213140'}>{count}</SText>
                                        </div>
                                        {product.availableones == count
                                            ? <div
                                                className={cn(styles.plus, product.availableones == count && styles.plusOpacity)}>
                                                <img src={plus}/></div>
                                            : <TouchableOpacity disabled={product.availableones == count}
                                                                onClick={() => handleCountChange(1)}>
                                                <div className={styles.plus}>
                                                    <img src={plus}/>
                                                </div>
                                            </TouchableOpacity>}
                                    </div>
                                    : <RoundedButton onPress={() => handleCountChange(1)}
                                                     containerStyle={{width: '180px', height: '50px'}}
                                                     label={product.availableones > 0 ? 'Добавить в корзину' : 'Сообщить о поступлении'}
                                                     activeColor={product.availableones > 0 ? Colors.orange : Colors.darkBlue}/>
                                }
                            </div>
                        </div>
                        <div className={styles.description} dangerouslySetInnerHTML={{__html: product.description}}>
                        </div>
                        {!!product.sostav ? <div className={styles.composition}>
                            <SText fontSize={14} fontWeight={400}>
                                {`Состав: ${product.sostav}`}
                            </SText>
                        </div> : null}
                        {(!!product.bel_amount && !!product.fats_amount && !!product.ugl_amount) ?
                            <div className={styles.proteins}>
                                <SText fontSize={14} fontWeight={400}>
                                    {`Пищевая ценность: белки - ${product.bel_amount}, жиры - ${product.fats_amount}, углеводы - ${product.ugl_amount} г (в 100 г продукта).`}
                                </SText>
                            </div> : null}
                        {!!product.calories_amount ? <div className={styles.energy}>
                            <SText fontSize={14} fontWeight={400}>
                                {`Энергетическая ценность: ${product.calories_amount} ккал (в 100 г продукта).`}
                            </SText>
                        </div> : null}
                    </div>
                </div>

            </div>
        </div>
    }

    return <>
        <Helmet>
            <title>{product.title}</title>
            <meta name="description" content={'Сизнмаркет'}/>
            <meta name="keywords" content={'Сизнмаркет'}/>
        </Helmet>
        {render()}
    </>
}

export default () => <div>
    <Header/>
    <ProductInfo/>
</div>
