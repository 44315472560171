
const $body = document.querySelector('body');

export function launchPayment({
    userId = 'test',
    preventRefresh = false,
    type = 1,
    amount = 1,
    orderId = 123,
    onSuccess = () => { },
    onFail = () => { },
    onComplete = () => { }
}) {

    const widget = new window.cp.CloudPayments();
    widget.pay('charge', {
        publicId: 'pk_2e2c8860c3ceb7eab0a55275630df', //id из личного кабинета
        description: type === 1 ? 'Оплата заказа ' + orderId : 'Добавление новой карты', //назначение
        amount: amount, //сумма
        currency: 'RUB', //валюта
        accountId: userId, //идентификатор плательщика (необязательно)
        invoiceId: orderId, //номер заказа  (необязательно)
        skin: "modern", //дизайн виджета (необязательно)
    },
        {
            onSuccess: (...args) => {
                onSuccess(...args)
                setTimeout(() => {
                    !preventRefresh && window.location.reload()
                }, 600)
            },
            onFail: (...args) => {
                onFail(...args)
                setTimeout(() => {
                    !preventRefresh && window.location.reload()
                }, 600)
            },
            onComplete: (...args) => {
                onComplete(...args)
                setTimeout(() => {
                    !preventRefresh && window.location.reload()
                }, 600)
            }
        }
    )
}
