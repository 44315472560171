import React, {useContext, useState} from "react";
import {NavLink} from "react-router-dom";
import styles from './HeaderBurger.module.css'
import SText from "./SText";
import DataContext from "../data/DataContext";
import ModalContext from "../data/ModalContext";
import {useRestrictions} from "../utils/hooks";
import {DOMAIN_URL} from "../config";
import Colors from "../utils/Colors";
import mail from "../assets/images/mailWhite.svg";
import help from '../assets/images/help.svg'
import wa from '../assets/images/waWhite.svg'

const HeaderBurger = ({
                          onClose = () => {
                          }
                      }) => {

    const {catalogue, user, auth} = useContext(DataContext)
    const {open: _open} = useContext(ModalContext)
    const [openPopup, setOpenPopup] = useState(false)

    const open = useRestrictions(_open)

    const onCategoryClick = (id) => {
        onClose()
        if (!auth.data?.auth) {
            window.mindbox("async", {
                operation: "OnlineCategoryView",
                data: {
                    viewProductCategory: {
                        productCategory: {
                            ids: {
                                sMProductID: id
                            }
                        }
                    }
                }
            });
        }
    }

    const categories = catalogue.data?.categories || []

    return <div className={styles.wrapper}>
        <div className={styles.user} onClick={() => {
            open('user')
            onClose()
        }}>
            <div className={styles.aboutName}>
                <div><SText fontSize={22} fontWeight={900}>{user.data?.full_name || ''}</SText></div>
                <div><SText fontSize={16} fontWeight={550}>{'Личный кабинет'}</SText></div>
            </div>
        </div>
        <div className={styles.categories}>
            {
                categories.map(category => <NavLink
                    to={'/catalog/' + category.url + '/' + category.subCategories[0]?.url} onClick={() => {
                    onCategoryClick(category.id)
                }}>
                    <div className={styles.category}>
                        <div className={styles.categoryIcon}>
                            <img src={DOMAIN_URL + category?.image_mob}/>
                        </div>
                        <div className={styles.categoryName}>
                            <SText fontSize={11} fontWeight={700}>
                                {category.name}
                            </SText>
                        </div>
                    </div>
                </NavLink>)
            }
        </div>
        <div className={styles.info}>
            <NavLink onClick={() => window.scrollTo(0, 0)} to={"/delivery-and-payment"}><SText fontSize={16}
                                                                                               fontWeight={550}>{'Доставка и оплата'}</SText></NavLink>
            <NavLink onClick={() => window.scrollTo(0, 0)} to={"/about-season-market"}><SText fontSize={16}
                                                                                              fontWeight={550}>{'О нас'}</SText></NavLink>
        </div>
        <div className={styles.contacts}>
            <div className={styles.helpBtn} onClick={() => setOpenPopup(prev => !prev)}>
                <img src={help}/>
                <SText fontSize={18} fontWeight={700} lineHeight={18} color={Colors.white}>Служба поддержки</SText>
            </div>
            {openPopup && <div className={styles.popUp}>
                <a href="tel:+74956460371">

                    <div className={styles.waBtn} onClick={() => setOpenPopup(false)}>
                        <img src={wa}/>
                        <SText fontSize={16} fontWeight={700} lineHeight={16} color={Colors.white}>Связаться по
                            WhatsApp</SText>
                    </div>
                </a>
                <a href="mailto:support@halalfoods.ru">
                    <div className={styles.mailBtn} onClick={() => setOpenPopup(false)}>
                        <img src={mail}/>
                        <SText fontSize={16} fontWeight={700} lineHeight={16} color={Colors.white}>Связаться по
                            почте</SText>
                    </div>
                </a>
            </div>}
        </div>
    </div>
}

export default HeaderBurger
