import React, {useContext, useEffect, useState} from "react";
import {useQuery} from "../../utils/hooks";
import {searchAll} from "../../api";
import Card from "../Catalogue/components/Card";
import DataContext from "../../data/DataContext";
import Header from "../../components/Header";
import SText from "../../components/SText";
import styles from "../Search/Found.module.css"
import {logDOM} from "@testing-library/react";
import {Helmet} from "react-helmet";
import BreadCrumbs from "../../components/BreadCrumbs";
import useSearch from "../../data/SearchContext";
import TouchableOpacity from "../../components/TouchableOpacity";
import {Redirect, useHistory} from "react-router-dom";
import {ProductCard} from "../../components/ProductCard";

export const SearchResult = ({id}) => {

    const [product, setProduct] = useState(null)

    const {getProductByEcoId} = useContext(DataContext)

    const loadProduct = async () => {
        const product = await getProductByEcoId(id)
        setProduct(product)
    }

    console.log(product)

    useEffect(() => {
        loadProduct()
    }, [id])

    if (!product || isNaN(product.price) || product?.availableones < 1) return null

    return <ProductCard id={product.id}/>

}


const Found = () => {

    const [data, setData] = useState([])
    const {onQueryChange, suggestions} = useSearch()
    const {push} = useHistory()

    const query = useQuery()
    const searchQuery = query.get('query')

    const search = async (string) => {
        const data = await searchAll(string).then(r => {
            console.log(r)
            return r?.results?.ids
        })
        console.log({data})
        setData(data)
    }

    useEffect(() => {
        if (searchQuery != '') {
            search(searchQuery)
            onQueryChange(searchQuery)
        }
    }, [searchQuery])

    if (searchQuery == '')
        return <Redirect to={'/'}/>

    return <div>
        <Header query={searchQuery}/>
        <Helmet>
            <title>{'Поиск: '+searchQuery}</title>
        </Helmet>
        <div className={styles.search}>
            <BreadCrumbs _items={[{name: 'Поиск', to: '/'}]}/>
            <div>
                <SText fontSize={24} fontWeight={900}>{'Поиск: ' + searchQuery}</SText>
            </div>
            <div className={styles.suggestions}>
                {suggestions.autocomplete.map(item=><TouchableOpacity className={styles.suggestionItem} onClick={()=>push('search?query='+item)}>
                    <SText fontWeight={400} fontSize={14}>{item}</SText>
                </TouchableOpacity>)}
            </div>
        </div>
        <div className={styles.wrapper}>
            {
                data.map(id => <SearchResult key={id} id={id}/>)
            }
        </div>
    </div>
}

export default Found
