import React, { useContext, useState } from "react";
import styles from "./Footer.module.css"
import footerLogo from "../assets/images/halalFooter.svg"
import mobileLogo from '../assets/images/logoMobFooter.svg'
import visa from "../assets/images/visaFooter.svg"
import masterCard from "../assets/images/masterCardFooter.svg"
import SText from "./SText";
import { NavLink } from "react-router-dom";
import { useRestrictions, useWindowDimensions } from "../utils/hooks";
import ModalContext from "../data/ModalContext";
import help from "../assets/images/help.svg";
import Colors from "../utils/Colors";
import wa from "../assets/images/waWhite.svg";
import mail from "../assets/images/mailWhite.svg";

const Footer = () => {

    const { open: _open } = useContext(ModalContext)
    const open = useRestrictions(_open)
    const { windowDimensions: { width } } = useWindowDimensions()
    const [openPopup, setOpenPopup] = useState(false)

    if (width < 850) {
        return <div className={ styles.mobileWrapper }>

            <div className={ styles.contacts }>
                <div className={ styles.helpBtn } onClick={ () => setOpenPopup(prev => !prev) }>
                    <img src={ help } />
                    <SText fontSize={ 18 } fontWeight={ 700 } lineHeight={ 18 } color={ Colors.white }>Служба поддержки</SText>
                </div>
                { openPopup && <div className={ styles.popUp }>
                    <a href="tel:+74956460371">

                        <div className={ styles.waBtn } onClick={ () => setOpenPopup(false) }>
                            <img src={ wa } />
                            <SText fontSize={ 16 } fontWeight={ 700 } lineHeight={ 16 } color={ Colors.white }>Связаться по
                                WhatsApp</SText>
                        </div>
                    </a>

                    <a href="mailto:support@halalfoods.ru">
                        <div className={ styles.mailBtn } onClick={ () => setOpenPopup(false) }>
                            <img src={ mail } />
                            <SText fontSize={ 16 } fontWeight={ 700 } lineHeight={ 16 } color={ Colors.white }>Связаться по
                                почте</SText>
                        </div>
                    </a>
                </div> }
            </div>

            <div className={ styles.line } />

            <div style={ { display: 'flex', justifyContent: 'space-between', width: '100%', padding: '0 20px' } }>
                <div>
                    <div className={ styles.company }>
                        <div className={ styles.companyHeader }><SText fontSize={ 14 } fontWeight={ 700 } lineHeight={ 16 }
                            color={ '#fff' }>КОМПАНИЯ</SText></div>
                        <div className={ styles.companyContent }>
                            <div style={ { marginBottom: '8px' } }><NavLink onClick={ () => {
                                window.scrollTo(0, 0);
                            } } to={ '/about-season-market' }><SText fontSize={ 14 }
                                fontWeight={ 400 }
                                color={ '#fff' }>О
                                    HalalFoods</SText></NavLink></div>
                        </div>
                    </div>

                    <div className={ styles.info }>
                        <div className={ styles.infoHeader }><SText fontSize={ 14 } fontWeight={ 700 }
                            color={ '#fff' }>ИНФОРМАЦИЯ</SText></div>
                        <div className={ styles.infoContent }>
                            <NavLink to={ '/delivery-and-payment' }>
                                <div onClick={ () => {
                                    window.scrollTo(0, 0);
                                } } style={ { marginBottom: '8px' } }><SText fontSize={ 14 } fontWeight={ 400 } color={ '#fff' }>Доставка
                                    и оплата</SText></div>
                            </NavLink>
                            <NavLink to={ '/bonus-system' }>
                                <div onClick={ () => {
                                    window.scrollTo(0, 0);
                                } } style={ { marginBottom: '8px' } }><SText fontSize={ 14 } fontWeight={ 400 } color={ '#fff' }>Бонусная
                                    система</SText></div>
                            </NavLink>
                            <NavLink to={ '/the-agreements-and-rules' }>
                                <div onClick={ () => {
                                    window.scrollTo(0, 0);
                                } } style={ { marginBottom: '8px' } }><SText fontSize={ 14 } fontWeight={ 400 } color={ '#fff' }>Соглашения
                                    и правила</SText></div>
                            </NavLink>
                            <NavLink to={ '/offer' }>
                                <div onClick={ () => {
                                    window.scrollTo(0, 0);
                                } } style={ { marginBottom: '8px' } }><SText fontSize={ 14 } fontWeight={ 400 } color={ '#fff' }>Оферта</SText></div>
                            </NavLink>
                        </div>
                    </div>
                </div>

                <div><img src={ mobileLogo } /></div>
            </div>

        </div>
    }

    return <footer className={ styles.wrapper }>
        <div className={ styles.container }>
            <NavLink style={ width <= 768 && { display: 'none' } } to={ '/' }>
                <div onClick={ () => {
                    window.scrollTo(0, 0);
                } } className={ styles.logo }>
                    <div style={ { marginBottom: 10 } }><img src={ footerLogo } /></div>
                </div>
            </NavLink>
            <NavLink to={ '/' }>
                <div onClick={ () => {
                    window.scrollTo(0, 0);
                } } className={ styles.logoFooterMobile }>
                    <div style={ { marginBottom: '9px' } }><img src={ footerLogo } /></div>
                </div>
            </NavLink>
            <div className={ styles.company }>
                <div className={ styles.companyHeader }><SText fontSize={ 14 } fontWeight={ 700 }
                    color={ '#fff' }>КОМПАНИЯ</SText></div>
                <div className={ styles.companyContent }>
                    <div style={ { marginBottom: '8px' } }><NavLink onClick={ () => {
                        window.scrollTo(0, 0);
                    } } to={ '/about-season-market' }><SText fontSize={ 14 }
                        fontWeight={ 400 }
                        color={ '#fff' }>О
                            HalalFoods</SText></NavLink></div>
                </div>
            </div>
            <div className={ styles.info }>
                <div className={ styles.infoHeader }><SText fontSize={ 14 } fontWeight={ 700 }
                    color={ '#fff' }>ИНФОРМАЦИЯ</SText></div>
                <div className={ styles.infoContent }>
                    <NavLink to={ '/delivery-and-payment' }>
                        <div onClick={ () => {
                            window.scrollTo(0, 0);
                        } } style={ { marginBottom: '8px' } }><SText fontSize={ 14 } fontWeight={ 400 } color={ '#fff' }>Доставка
                            и оплата</SText></div>
                    </NavLink>
                    <NavLink to={ '/bonus-system' }>
                        <div onClick={ () => {
                            window.scrollTo(0, 0);
                        } } style={ { marginBottom: '8px' } }><SText fontSize={ 14 } fontWeight={ 400 } color={ '#fff' }>Бонусная
                            система</SText></div>
                    </NavLink>
                    <NavLink to={ '/the-agreements-and-rules' }>
                        <div onClick={ () => {
                            window.scrollTo(0, 0);
                        } } style={ { marginBottom: '8px' } }><SText fontSize={ 14 } fontWeight={ 400 } color={ '#fff' }>Соглашения
                            и правила</SText></div>
                    </NavLink>
                    <NavLink to={ '/offer' }>
                        <div onClick={ () => {
                            window.scrollTo(0, 0);
                        } } style={ { marginBottom: '8px' } }><SText fontSize={ 14 } fontWeight={ 400 } color={ '#fff' }>Оферта</SText></div>
                    </NavLink>
                </div>
            </div>
            <div className={ styles.personalAccount }>
                <div className={ styles.personalHeader }><SText fontSize={ 14 } fontWeight={ 700 }
                    color={ '#fff' }>ЛИЧНЫЙ КАБИНЕТ</SText></div>
                <div className={ styles.personalContent }>
                    <div onClick={ () => {
                        open('user')
                    } } style={ { cursor: 'pointer', marginBottom: '8px' } }><SText fontSize={ 14 } fontWeight={ 400 }
                        color={ '#fff' }>Мой
                            аккаунт</SText></div>
                    <div onClick={ () => {
                        open('userHistory')
                    } } style={ { cursor: 'pointer', marginBottom: '8px' } }><SText fontSize={ 14 } fontWeight={ 400 }
                        color={ '#fff' }>История
                            заказов</SText></div>
                    <div className={ styles.personalCards }>
                        <div style={ { marginRight: 11 } }><img src={ visa } /></div>
                        <div><img src={ masterCard } /></div>
                    </div>
                </div>
            </div>

            <div className={ styles.personalAccount }>
                <div className={ styles.personalHeader }><SText fontSize={ 14 } fontWeight={ 700 }
                    color={ '#fff' }>СВЯЗАТЬСЯ С НАМИ</SText></div>
                <div className={ styles.personalContent }>
                    <a href="tel:+74956460371">
                        <div style={ { cursor: 'pointer', marginBottom: '8px' } }><SText fontSize={ 14 } fontWeight={ 400 }
                            color={ '#fff' }>+7 (495) 646-03-71</SText></div>
                    </a>
                </div>
            </div>

            <div className={ styles.contacts }>
                <div className={ styles.helpBtn } onClick={ () => setOpenPopup(prev => !prev) }>
                    <img src={ help } />
                    <SText fontSize={ 18 } fontWeight={ 700 } lineHeight={ 18 } color={ Colors.white }>Служба поддержки</SText>
                </div>
                { openPopup && <div className={ styles.popUp }>
                    <a href="tel:+74956460371">

                        <div className={ styles.waBtn } onClick={ () => setOpenPopup(false) }>
                            <img src={ wa } />
                            <SText fontSize={ 16 } fontWeight={ 700 } lineHeight={ 16 } color={ Colors.white }>Связаться по
                                WhatsApp</SText>
                        </div>
                    </a>

                    <a href="mailto:support@halalfoods.ru">
                        <div className={ styles.mailBtn } onClick={ () => setOpenPopup(false) }>
                            <img src={ mail } />
                            <SText fontSize={ 16 } fontWeight={ 700 } lineHeight={ 16 } color={ Colors.white }>Связаться по
                                почте</SText>
                        </div>
                    </a>
                </div> }
            </div>

        </div>
    </footer>
}

export default Footer
